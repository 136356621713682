/*============================================================================
  A generic way to visually hide content while
  remaining accessible to screen readers (h5bp.com)
==============================================================================*/
.icon__fallback-text {
  @include visually-hidden;

  .no-svg & {
    // sass-lint:disable no-important
    position: static !important;
    overflow: inherit;
    clip: none;
    height: auto;
    width: auto;
    margin: 0;
  }
}
