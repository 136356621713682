/*** Yopt css overrides ***/
/*************************/
.yotpo-widget-override-css.yotpo-widget-referral-widget {
	max-height: unset !important;
}

.main-widget .yotpo-label-container {
	text-align: center;
}

/*** Ada Chatbot ***/
/******************/

.cart #ada-button-frame {
	display: none;
}

.product #ada-button-frame {
	display: none;
}

#ada-button-frame {
	z-index: $zindex-1 !important;
}

.swiper-slide {
	-webkit-transform: translateZ(0);
	-webkit-backface-visibility: hidden;
}

.slick-track,
.slick-list {
	-webkit-perspective: 2000;
	-webkit-backface-visibility: hidden;
}