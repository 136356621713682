@use 'sass:math';

/***------ KEYFRAMES ------***/
@mixin keyframes($name) {
	@-webkit-keyframes #{$name} {
		@content;
	}

	@-moz-keyframes #{$name} {
		@content;
	}

	@-ms-keyframes #{$name} {
		@content;
	}

	@keyframes #{$name} {
		@content;
	}
}

/***------ VERTICAL CENTERING ------***/
@mixin vertical-center {
	position: relative;
	top: 50%;
	-ms-transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}

/***------ THEE HOVER EFFECT ------***/
@mixin hoverable($borderHover: $blue-tint) {
	padding: 0.8rem;
	gap: 0.8rem;
	border-radius: $radius-2;
	border: 1px solid $gray-2;

	background-color: $white;

	cursor: pointer;
	transition: all 200ms cubic-bezier(0.19, 1, 0.22, 1);
	user-select: none;
	-webkit-user-select: none;

	* {
		transition: all 200ms cubic-bezier(0.19, 1, 0.22, 1);
	}

	&:focus-visible {
		border-color: $blue-tint;
		box-shadow: 0 0 0 1px $blue-tint;
		outline: 0;
	}

	@media (hover: hover) {
		&:active {
			background-color: $gray-2;
			border-color: $blue-shade;
		}

		&:hover:not(:active):not(:focus-visible) {
			border-color: $borderHover;
			box-shadow: -1px 1px $borderHover;
		}
	}
}

@mixin hoverableNoPadding($borderHover: $blue-tint) {
	border-radius: $radius-2;
	border: 1px solid $gray-2;

	background-color: $white;

	cursor: pointer;
	transition: all 200ms cubic-bezier(0.19, 1, 0.22, 1);
	user-select: none;

	* {
		transition: all 200ms cubic-bezier(0.19, 1, 0.22, 1);
	}

	&:focus-visible {
		border-color: $blue-tint;
		box-shadow: 0 0 0 1px $blue-tint;
		outline: 0;
	}

	@media (hover: hover) {
		&:active {
			background-color: $gray-2;
			border-color: $blue-shade;
		}

		&:hover:not(:active):not(:focus-visible) {
			border-color: $borderHover;
			box-shadow: -1px 1px $borderHover;
		}
	}
}

@mixin hoverable-outline($borderHover: $blue-tint, $focus: $blue-tint) {
	padding: 0.8rem;
	gap: 0.8rem;
	border-radius: $radius-2;
	border: 1px solid transparent;

	background-color: $white;

	cursor: pointer;
	transition: all 200ms cubic-bezier(0.19, 1, 0.22, 1);

	* {
		transition: all 200ms cubic-bezier(0.19, 1, 0.22, 1);
	}

	&:focus-visible {
		border-color: $focus;
		box-shadow: 0 0 0 1px $focus;
		outline: 0;
	}

	&:active {
		background-color: $gray-2;
		border-color: $borderHover;
	}

	@media (hover: hover) {
		&:hover:not(:active):not(:focus-visible) {
			border-color: $borderHover;
			outline: 1px solid $borderHover;
			transition: 0s;
		}
	}
}

@mixin hoverable-boxshadow($borderHover: $blue-tint, $focus: $blue-tint) {
	padding: 0.8rem;
	gap: 0.8rem;
	border-radius: $radius-2;
	border: 1px solid transparent;

	background-color: $white;

	cursor: pointer;
	transition: all 200ms cubic-bezier(0.19, 1, 0.22, 1);

	* {
		transition: all 200ms cubic-bezier(0.19, 1, 0.22, 1);
	}

	&:focus-visible {
		border-color: $focus;
		box-shadow: 0 0 0 1px $focus;
		outline: 0;
	}

	&:active {
		background-color: $gray-2;
		border-color: $borderHover;
	}

	@media (hover: hover) {
		&:hover:not(:active):not(:focus-visible) {
			border-color: $borderHover;
			box-shadow: -1px 1px 0px 0px $borderHover;
			-webkit-box-shadow: -1px 1px 0px 0px $borderHover;
			-moz-box-shadow: -1px 1px 0px 0px $borderHover;
			transition: border-color 0.2s ease-out, box-shadow 0.2s ease-out;
		}
	}
}

// TODO: Temporally used for the build flow card, should be removed once the button component is updated
@mixin button-hover-effect($background-color: $gray-1, $border-color: $gray-3, $shadow-color: $gray-3) {
	&:hover:not(:active):not(:focus-visible) {
		background-color: $background-color;
		border-color: $border-color;
		box-shadow: 1px -1px 0px 1px $shadow-color inset;

		span {
			color: inherit;

			&[data-text-animate] {
				transform: translate3d(2px, -2px, 0);
				transition: all cubic-bezier(0.19, 1, 0.22, 1) 200ms;
			}
		}
	}
}

@mixin active($borderHover: $blue-tint) {
	padding: 0.8rem;
	gap: 0.8rem;
	border-color: $blue-tint;
	box-shadow: 0 0 0 1px $blue-tint;

	background-color: $white;

	cursor: pointer;
	user-select: none;
}

@mixin visually-hidden() {
	// sass-lint:disable no-important
	position: absolute !important;
	overflow: hidden;
	clip: rect(0 0 0 0);
	height: 1px;
	width: 1px;
	margin: -1px;
	padding: 0;
	border: 0;
}

@mixin navbarUserActionIcon {
	background-color: $white;
	border: 0.15rem solid $white;
	border-radius: 50%;
	height: 4.8rem;
	width: 4.8rem;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	
	svg {
		display: flex;
		justify-content: center;
		align-items: center;
		transition: 100ms ease-in;

		path {
			transition: 100ms ease-in;
		}
	}

	@media screen and (hover: hover) {
		&:hover {
			background-color: $gray-2;
			cursor: pointer;
		}

		&:active {
			background-color: $gray-2;
			border: 0.15rem solid $gray-3;
		}
	}
}

/** Creates multiple classes to accommodate a range of rem values for a specified property **/
/** Implements the formula n => (2^n)/10, so at $i == 3, the property will have 0.8rem **/
@mixin createRemRange($property, $loopLength) {
	@for $i from 1 through $loopLength {
		&--#{$i}#{$property} {
			#{$property}: #{math.div(math.pow(2, $i), 10)}rem;
		}
	}
}

@mixin navigationButton {
	z-index: $zindex-4;
	position: absolute;
	height: 4.8rem;
	width: 4.8rem;
	border-radius: 100%;
	background-color: $white;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border: 1.5px solid $gray-2;
	user-select: none;

	@media (hover: hover) {
		&:hover {
			background-color: $gray-2;
			transition: background-color 0.3s ease;
		}
	}

	@media (max-width: $media-md) {
		display: none;
	}
}

// TODO - remove this
// As above, this styling logic is inherently tied to a specific type of element, so it should be captured within
// a component, rather than a mixin
@mixin siteResponsiveContentContainer() {
	position: relative;
	min-height: 50vh;
	padding: 1rem;

	@media screen and (min-width: $media-lg) {
		padding: 1rem 3rem;
	}

	@media screen and (min-width: $media-xxl) {
		padding: 1rem 8rem;
	}
}

@mixin siteResponsiveCardGrid() {
	@supports (display: grid) {
		display: grid;
		grid-template-columns: 1fr;
		gap: 0 1rem;
	}

	@supports not (display: grid) {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
	}

	@media screen and (min-width: $media-md-max) {
		@supports (display: grid) {
			grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
		}
	}

	@media screen and (min-width: $media-xl) {
		@supports (display: grid) {
			grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
		}
	}
}

@mixin noScrollBar() {
	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}
}

@mixin roundActionButton {
	width: 40px;
	height: 40px;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background-color: $gray-0;
	border: 2px solid $gray-2;
	z-index: 10;
	cursor: pointer;

	@media screen and (min-height: $iphone-13-height) and (max-height: $iphone-13-width) {
		width: 3.2rem;
		height: 3.2rem;
	}
}

@mixin skeleton-loader() {
	background: linear-gradient(to right,
			rgba(255, 255, 255, 0),
			rgba(255, 255, 255, 0.1) 40%,
			rgba(255, 255, 255, 0.2) 50%,
			rgba(255, 255, 255, 0.1) 60%,
			rgba(255, 255, 255, 0) 80%),
		$gray-2;
	background-repeat: repeat-y;
	background-size: 50px 300px;
	background-position: 0 0;
	animation: shine 1s infinite;
	transition: background-position $transition-3 ease-in;
}

@keyframes shine {
	to {
		background-position: 100% 0, 0 0;
	}
}

@mixin loading-wrapper-shimmer {
	position: relative;

	&::after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		max-width: $max-width;
		transform: translateX(-100%);
		background-image: linear-gradient(90deg,
				rgba($gray-2, 0) 0,
				rgba($gray-2, 0.2) 20%,
				rgba($gray-2, 0.5) 60%,
				rgba($gray-2, 0));
		animation: shimmer 2s infinite;
		content: '';
	}

	@keyframes shimmer {
		100% {
			transform: translateX(100%);
		}
	}
}

/***------ TOOLTIP ------***/

@mixin tooltipMessage {
	box-shadow: 0 0.4rem 3.2rem 0 #00000029;
	border-radius: 0.4rem;
	padding: 1.6rem;
	opacity: 1;
	background: $white;
	width: 24.4rem;
	color: $black;
	z-index: 1;
}

@mixin tooltipContent {
	animation: slideDown;
	animation-duration: 400ms;
	animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
	transform-origin: var(--radix-tooltip-content-transform-origin);
	z-index: $zindex-3;

	&[data-state='closed'] {
		opacity: 0;
	}
}

$heights: 128, 256, 375, 444, 512, 767, 932;

@mixin height-calculation {
	@each $height in $heights {
		&-#{$height} {
			height: calc(#{$height} / 10 * 1rem);
		}
	}
}

$positions: 'top-left', 'top-center', 'top-right', 'center-left', 'center', 'center-right', 'bottom-left', 'bottom-center',
	'bottom-right';
$alignments: 'left', 'center', 'right';

@mixin content-position {
	@each $p in $positions {
		&.position__#{$p} {
			@if $p =='top-left' {
				align-items: flex-start;
				justify-items: flex-start;
			}

			@else if $p =='top-center' {
				align-items: flex-start;
				justify-items: center;
			}

			@else if $p =='top-right' {
				align-items: flex-start;
				justify-items: flex-end;
			}

			@else if $p =='center-left' {
				align-items: center;
				justify-items: flex-start;
			}

			@else if $p =='center' {
				align-items: center;
				justify-items: center;
			}

			@else if $p =='center-right' {
				align-items: center;
				justify-items: flex-end;
			}

			@else if $p =='bottom-left' {
				align-items: flex-end;
				justify-items: flex-start;
			}

			@else if $p =='bottom-center' {
				align-items: flex-end;
				justify-items: center;
			}

			@else if $p =='bottom-right' {
				align-items: flex-end;
				justify-items: flex-end;
			}
		}
	}
}

@mixin content-alignment {
	@each $a in $alignments {
		&.alignment__#{$a} {

			h1,
			h2,
			h3,
			h4,
			h5,
			h6,
			p,
			div {
				text-align: #{$a} !important;
			}

			@if $a =='left' {
				justify-items: flex-start;

				/* Targets 'Component: Button Grouping' section */
				[class*='Flex_container'],
				[class*='painted'] {
					justify-content: flex-start;
					justify-items: flex-start;
				}

				/* Targets 'Component: Button' */
				[class*='Button_button'],
				[class*='Link_link'] {
					align-self: flex-start !important;
				}

				[class*='rich-text-wrapper'] {
					text-align: #{$a} !important;
				}
			}

			@else if $a =='center' {
				justify-items: center;

				/* Targets 'Component: Button Grouping' section */
				[class*='Flex_container'],
				[class*='painted'] {
					justify-content: center;
					justify-items: center;
				}

				/* Targets 'Component: Button' */
				[class*='Button_button'],
				[class*='Link_link'] {
					align-self: center !important;
				}

				[class*='rich-text-wrapper'] {
					text-align: #{$a} !important;
				}
			}

			@else if $a =='right' {
				justify-items: flex-end;

				/* Targets 'Component: Button Grouping' section */
				[class*='Flex_container'],
				[class*='painted'] {
					justify-content: flex-end;
					justify-items: flex-end;
				}

				/* Targets 'Component: Button' */
				[class*='Button_button'],
				[class*='Link_link'] {
					align-self: flex-end !important;
				}

				[class*='rich-text-wrapper'] {
					text-align: #{$a} !important;
				}
			}
		}
	}
}

@mixin non-selectable {
	-webkit-user-select: none;
	/* Safari */
	-moz-user-select: none;
	/* Firefox */
	-ms-user-select: none;
	/* IE10+/Edge */
	user-select: none;
	/* Standard */
}

@mixin top-frame-shadow {
	filter: drop-shadow(0.4rem 0.8rem 0.4rem rgba(0, 0, 0, 0.2));
}

@mixin swatch-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 4rem;
	height: 4rem;
	padding: 2px;
	border: 2px solid transparent;
	border-radius: 100%;
	transition: 200ms border-color ease-out;

	&[data-selected='true'],
	&:hover {
		border-color: $blue-1;
		transition: 200ms border-color ease-out;
	}
}

@mixin swatch {
	width: 3.2rem;
	height: 3.2rem;
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	border-radius: 50%;
	line-height: 1;
	padding: 0;

	aspect-ratio: 1;
	cursor: pointer;

	// Fixes Safari "Octagon" Shape
	// Semi-relevant context around why the issue occurs here: https://stackoverflow.com/questions/17202128/rounded-cornes-border-radius-safari-issue
	clip-path: none !important;
}

@mixin hide-scroll-bar {
	// Hide scroll bar
	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		// Safari way to hide scroll bar
		display: none;
	}
}

@mixin padding-inline {
	padding-inline: 1.6rem;

	@media screen and (width >=$media-xl) {
		padding-inline: 3.2rem;
	}

	@media screen and (width >=$media-xxl) {
		padding-inline: 6.4rem;
	}

	@media screen and (width >=$max-width) {
		padding-inline: 12.8rem;
	}
}

@mixin padding-block {
	padding-block: 1.6rem;

	@media screen and (width >=$media-xl) {
		padding-block: 3.2rem;
	}
}

@mixin product-grid-padding($removeInlineMobilePadding: false,
	$baseInlinePadding: 0.8rem,
	$baseBlockPadding: 0.8rem,
	$removePaddingXXL: false) {
	padding-block: $baseBlockPadding;
	padding-inline: $baseInlinePadding;

	@if $removeInlineMobilePadding {
		@media screen and (width < $media-md) {
			padding-inline: 0;
		}
	}

	@media screen and (width >=$media-md) {
		padding-inline: $baseInlinePadding * 4;
	}

	@media screen and (width >=$media-xl) {
		padding-inline: $baseInlinePadding * 4;
	}

	@if not $removePaddingXXL {
		@media screen and (width >=$media-xxl) {
			padding-inline: $baseInlinePadding * 8;
		}
	}
}

/***------ CONTENTFUL ------***/
@mixin cf-margin-block {
	$margin-blocks: 0.8, 1.6, 3.2, 4.8, 6.4;

	@each $mb in $margin-blocks {
		&.mb-#{index($margin-blocks, $mb)} {
		  	margin-block: #{$mb}rem;
		}
	}
}

@mixin cf-max-width {
	$max-widths: 375, 576, 767, 1024, 1280, 1440;
	
	@each $mw in $max-widths {
		&.max-width-#{$mw} {
		  	max-width: #{math.div($mw, 10)}rem;
		}
	}
}

@mixin cf-border-radius {
	$border-radii: 0.4, 0.8, 1.2, 1.6;

	@each $br in $border-radii {
		&.br-#{index($border-radii, $br)} {
			border-radius: #{$br}rem;
		}
	}
}

@mixin cf-height($appliedClass: false, $applyToAllChildren: false) {
	&.auto {
		@if $appliedClass {
			> .#{$appliedClass} {
				height: auto;
			}
		}

		@if $applyToAllChildren {
			> * {
				height: auto;
			}
		}
	}

	&.small {
		@if $appliedClass {
			> .#{$appliedClass} {
				height: $height-256;
			}
		}

		@if $applyToAllChildren {
			> * {
				height: $height-256;
			}
		}
	}

	&.medium {
		@if $appliedClass {
			> .#{$appliedClass} {
				height: $height-375; // Smaller design for mobile

				@media screen and (min-width: $media-md) {
					height: $height-512;
				}
			}
		}

		@if $applyToAllChildren {
			> * {
				height: $height-512;
			}
		}
	}

	&.large {
		@if $appliedClass {
			> .#{$appliedClass} {
				height: $height-512; // Smaller design for mobile
				
				@media screen and (min-width: $media-md) {
					height: $height-767;
				}
			}
		}

		@if $applyToAllChildren {
			> * {
				height: $height-767;
			}
		}
	}
}
