@import '__importable.scss';
.container {
    div[class^="Hero_container__"]{
		&:first-child {
			padding: 0;
		}

		&:nth-last-of-type(2) {
			padding-bottom: 0;
		}
	}
}
