@import 'mixins';

/***------ KEYFRAMES ------***/
@include keyframes(dots) {
	0%,
	20% {
		color: rgba(0, 0, 0, 0);
		text-shadow: 0.4rem 0 0 rgba(0, 0, 0, 0), 0.8rem 0 0 rgba(0, 0, 0, 0);
	}

	40% {
		color: currentColor;
		text-shadow: 0.4rem 0 0 rgba(0, 0, 0, 0), 0.8rem 0 0 rgba(0, 0, 0, 0);
	}

	60% {
		text-shadow: 0.4rem 0 0 currentColor, 0.8rem 0 0 rgba(0, 0, 0, 0);
	}

	80%,
	100% {
		text-shadow: 0.4rem 0 0 currentColor, 0.8rem 0 0 currentColor;
	}
}

@include keyframes(beat) {
	0%,
	50%,
	100% {
		transform: scale(1.3);
	}

	30%,
	70%,
	80% {
		transform: scale(0.9);
	}

	90% {
		transform: scale(1.1);
	}

	95% {
		transform: scale(0.95);
	}
}

@include keyframes(fadeIn) {
	from {
		opacity: 0;
        visibility: hidden;
	}

	to {
		opacity: 1;
        visibility: visible;
	}
}

@include keyframes(fadeOut) {
	from {
		opacity: 1;
        visibility: visible;
	}

	to {
		opacity: 0;
        visibility: hidden;
	}
}

@include keyframes(slideDown) {
	from {
		opacity: 0;
		translate: 0 -8px;
	}
	to {
		opacity: 1;
		translate: 0 0px;
	}
}

@include keyframes(fadeInLeft) {
    0% {
        -webkit-transform: translateX(-24px);
        transform: translateX(-24px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

/***------ CLASSES ------***/
.fetching:after {
	content: '.';
	animation: dots 1s steps(5, end) infinite;
}
